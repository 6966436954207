.animate-loading {
  font-size: 40;
  animation: zoomin 1s infinite alternate;
}

@keyframes zoomin {
  from {
    transform: scale(1.5);
  }
  to {
    transform: scale(2.3);
  }
}

  /* seconda loading animation */
  .waviy {
    position: relative;
   // -webkit-box-reflect: below -200px linear-gradient(transparent, rgba(0,0,0,.2));
  }
  .waviy .letter-animate {
    position: relative;
    display: inline-block;
    animation: waviy 1s infinite;
    //animation-delay: calc(.1s * var(--i));
    @for $i from 1 through 7 {
      &:nth-child(#{$i+1}) {
        animation-delay: #{$i*.1}s;
      }
    }
    
  }
  @keyframes waviy {
    0% {
      transform: translateY(0)
    }
    20% {
      transform: translateY(-25px)
    }
  }